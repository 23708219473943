<template>
    <v-card
        elevation="10"
        class="mx-auto"
    >

        <v-toolbar
            dark
            cards                
            flat
            outlined
            color="primary"
        >
            <v-card-title class="text-h6 font-weight-regular">
            Import
            </v-card-title>
            <v-spacer> </v-spacer>
            <v-btn 
                @click= "close"
                icon>
                <v-icon>mdi-close-thick</v-icon>
            </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-title>

        </v-card-title>
        <v-stepper v-model="step">
            <v-stepper-header>
            <v-stepper-step
                :complete="step > 1"
                step="1"
                class="ml-8"
            >
                Datei auswählen
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="step > 2"
                step="2"
            >
                Felder auswählen
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
                :complete="step > 2"
                step="3"
                class="mr-8"
            >
                Bericht
            </v-stepper-step>
            </v-stepper-header>
            <v-divider></v-divider>

            <v-stepper-items>
            <v-stepper-content step="1">
                    <v-form
                    ref="form"
                    v-model="form"
                    @submit.prevent="submit"
                    enctype="multipart/form-data"
                    class="pa-4 pt-6"
                >
                <v-card
                class="mb-12"
                color="grey lighten-1"
                height="400px"
                >
                
                    <v-row class="ma-5">
                        <v-file-input
                            ref="file"
                            v-model="file"
                            :rule="filerules"
                            label="Shapefile im zip fromat"
                            outlined
                            filled
                            background-color="#FFFFFF"
                            accept=".zip"
                            truncate-length="55"
                            class="mt-5"
                            @change="selectFile"
                        ></v-file-input>
                        <v-alert type="error" v-if="message != ''">
                        {{message}}
                        </v-alert>
                    </v-row>
                </v-card>
                <v-row class="ma-5">
                <v-btn @click= "close" text>
                Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="file == undefined"
                    :loading="isLoading"
                    dark
                    color="primary"
                    depressed
                    type="submit"              
                >
                    Weiter
                </v-btn>
                </v-row>
                </v-form>
                </v-stepper-content>

            <v-stepper-content step="2">
        
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="items"
                        item-key="properties.bez"
                        show-select
                        @item-selected="onItemSelected"
                        @toggle-select-all="onAllSelected"
                        :items-per-page="-1"
                        class="elevation-1 mb-12"
                        height="400px"
                    >

                    </v-data-table>          
        
                <v-row class="ma-5"> 
                    <v-btn @click="step = 1" text>
                    Zurück
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn
                    color="primary"
                    :disabled="!isSelected"
                    @click="importFields"
                    >
                    Importieren
                    </v-btn>
                </v-row>
            </v-stepper-content>
            <v-stepper-content step="3">
        
                    <v-data-table
                        v-model="selected"
                        :headers="headersImport"
                        :items="itemsImport"
                        item-key="name"
                        :items-per-page="-1"
                        class="elevation-1 mb-12"
                        height="400px"
                    >
                    <template v-slot:item.isErr="{ item }">
                    <v-icon
                        v-if="item.isErr"
                        large
                        color="red"
                        class="mr-2"
                    >
                        mdi-alert-circle
                    </v-icon>
                    <v-icon
                        v-if="!item.isErr"
                        large
                        color="green"
                        class="mr-2"
                    >
                        mdi-check-bold
                    </v-icon>
                    </template>
                    </v-data-table>          
        
                <v-row class="ma-5"> 
                    <v-btn @click="step = 2" text>
                    Zurück
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn
                    color="primary"
                    @click="close"
                    >
                    Close
                    </v-btn>
                </v-row>
            </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
    
</template>

<script>

import UploadFileService from "../../services/upload-file-services";
// import CompService from "../../services/comp.service";
//var shapefile = require("shapefile");

export default {

        props:{
            isVisible:{                
                required: true,
                default: false,
                type: Boolean
            }
        },

    data () {
        return {
            name: "FiledImport",
            step: 1, 
            filerules: [
                value => !value,
            ],     

            form: false,
            isLoading: false,
            loading: false,
            message: "",
            file: undefined,
            selected: [],
            headers: [
                { text: 'Name', value: 'properties.bez' },
                { text: 'Fläche in ha', value: 'properties.flaeche_ha' },
            ],
            items:[],
            headersImport: [
                { text: 'Status', value: 'isErr' },
                { text: 'Name', value: 'name' },
                { text: 'Bericht', value: 'msg' },
           ],
            itemsImport:[],
            
            isSelected:false
        }
    },

    computed: {
        loggedIn: function() {
            return this.$store.state.auth.status.loggedIn;
        },

        company: function() {
            return this.$store.state.app.actCompany.meta
        },

    },
    methods:{

        onItemSelected(val){
            val.item.selected = val.value;
            console.debug('onItemSelected', val.item)
            const sendItems = this.items.filter(x => x.selected == true)
            this.isSelected =  sendItems.length > 0
        },

        onAllSelected(val){
            for(let item of val.items){
                item.selected = val.value;
            }
            console.debug('onAllSelected', val.items)
            const sendItems = this.items.filter(x => x.selected == true)
            this.isSelected =  sendItems.length > 0

        },

       
        selectFile(file) {
            console.debug('selectFile', file);
            //this.file= file;
        },
        submit() {
            console.debug('submit', this.file);
            this.items = [];
            this.itemsImport = [];
            this.selected = [];
 
            UploadFileService.shape2json(this.file).then(
                (response) => {
                
                    console.debug("UploadFileService:", response);
                    this.step = 2;
                    this.items = response.data.res;
                    
                    this.selected = [];
  
                },
                (error) => {
                //todo
            
                console.log((error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString())
                }
            );
            
            //this.$emit('submit')


        },

        importFields(){
            console.debug('importFields', this.selected);
            this.itemsImport = [];

            //    this.$store.dispatch("app/postApiSetStore", {mutation:"importFields" , path:"api/company/fields/import", param:{compid:this.company.id, data:this.selected}})
               this.$store.dispatch("app/postApiSetStore", {mutation:"importFields" , path:"api/company/fields/import", param:{store:"fields/items", compid:this.company.id, data:this.selected}})
                .then((response)=>{ 

                    console.debug("importFields:", response);
                    this.step = 3;
                    this.itemsImport = response.data.status; 

                })
                .catch((error)=>{
                    console.error(error);
                })


        },


        close () {
            console.debug('close')
            this.$emit('close')
      
        },

    },
    watch: { 
        isVisible(newVal){
            if(!newVal){
                this.itemsImport = []
                this.items = []
                this.message = ""
                this.file = undefined
                this.selected = []
                this.step = 1
            }
        }
    },    
}
</script>


            