
import axios from 'axios';
import authHeader from './auth-header';
const apiconfig = require("../config/api.config");
const API_URL = apiconfig.API_URL +"/api";

class UploadFileService {

    shape2json(file){
        console.log("shape2json", file);
        let formData = new FormData();

        formData.append("file", file);  
        console.log("shape2json", formData.get('file'));    
        var headers =  {headers: authHeader()};
        headers.headers["Content-Type"] = "multipart/form-data";
    
        return axios.post(API_URL +  "/helper/shape2json", formData, headers);
        
    }

    shape2jsonZip(file){
        console.log("shape2json", file);
        let formData = new FormData();

        formData.append("file", file);  
        console.log("shape2json", formData.get('file'));    
        var headers =  {headers: authHeader()};
        headers.headers["Content-Type"] = "multipart/form-data";
 
        return axios.post(API_URL +  "/helper/shape2json", formData, headers);
    
    }

}

export default new UploadFileService();